"use client";

import { usePathname } from "next/navigation";
import Link from "@/components/Link";
import IconBox from "@/components/IconBox";

export const orgMenuItems = [
  {
    title: "Dashboard",
    description: "Get an overview of your organisation's activity",
    icon: "dashboard",
    color: "purple",
    href: `/dashboard`,
    dashboardItem: false,
  },
  {
    title: "Projects",
    description: "View and manage your organisation's projects",
    icon: "project",
    color: "magenta",
    href: `/projects`,
    dashboardItem: false,
  },
  {
    title: "Team",
    description: "Invite and manage access for your team members",
    icon: "team",
    color: "yellow",
    href: `/team`,
    dashboardItem: true,
  },
  {
    title: "Billing",
    description: "View and manage your billing and payment information",
    icon: "payment",
    color: "green",
    href: `/billing`,
    dashboardItem: true,
  },
  {
    title: "Settings",
    description:
      "Update your organisation's name, description, and other settings",
    icon: "settings",
    color: "blue",
    href: `/settings`,
    dashboardItem: true,
    secondary: true,
  },
];

function OrgLink({ href, icon, color, title, description, active }) {
  return (
    <Link
      href={href}
      className={`group flex gap-3 items-center ${
        active
          ? "opacity-100 text-1"
          : "opacity-90 hover:opacity-100 text-3 hover:text-1 transition-colors"
      } py-2 cursor-pointer`}
      title={description}
    >
      <IconBox size="md" color={color} icon={icon} />
      <span className="hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-sm xl:text-base leading-6">
        {title}
      </span>
    </Link>
  );
}

export default function OrgMenu() {
  const pathname = usePathname();

  return (
    <ul className={`flex flex-col items-stretch justify-start pb-12`}>
      {orgMenuItems.map((item) => (
        <li key={item.href}>
          <OrgLink
            href={item.href}
            icon={item.icon}
            color={item.color || "purple"}
            title={item.title}
            description={item.description}
            active={Boolean(pathname.includes(item.href))}
          />
        </li>
      ))}
    </ul>
  );
}
